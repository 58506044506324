import { FillProperty } from 'csstype'
import React from 'react'
import { colors } from '../../../__styling/settings/colors'

export interface PerthNowCentralLogoProps {
    className?: string
    width?: number
    height?: number
    fill?: FillProperty
    title?: string
}

const PerthNowCentralLogo: React.FC<PerthNowCentralLogoProps> = ({
    className,
    title,
    width = 130,
    height = 40,
    fill = colors.black,
}) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 132 58"
            preserveAspectRatio="xMinYMid meet"
        >
            <title>{title || 'Perth Now Central'}</title>
            <path
                d="M14.0495 57.0388C17.824 57.0388 20.2984 56.3562 22.2276 55.4604V49.5304C20.7597 50.1277 18.8724 50.7676 15.9367 50.7676C10.5686 50.7676 8.68133 47.6959 8.68133 42.022V40.6995C8.68133 35.0255 11.3235 32.7217 16.0206 32.7217C18.5369 32.7217 20.2145 33.1057 21.9759 33.7456V27.7303C20.3403 26.9198 17.9917 26.4505 14.385 26.4505C6.37469 26.4505 0 30.5033 0 41.2967V42.3206C0 52.3461 5.24235 57.0388 14.0495 57.0388Z"
                fill="black"
            />
            <path
                d="M35.5713 56.9535C39.6813 56.9535 42.4492 55.9296 44.1268 54.9058V50.2556C42.4073 50.9809 40.1007 51.6208 37.4585 51.6208C33.684 51.6208 31.7129 50.6396 31.2516 47.0987H45.0495V45.1363C45.0495 37.6705 42.2396 33.4043 34.7745 33.4043C28.0642 33.4043 23.7446 37.7985 23.7446 44.8376V45.3922C23.7446 52.9007 27.6449 56.9535 35.5713 56.9535ZM34.7745 38.3531C36.9133 38.3531 37.9618 39.5476 37.9618 42.8752H31.2097C31.5452 39.3343 33.0969 38.3531 34.7745 38.3531Z"
                fill="black"
            />
            <path
                d="M47.3938 56.5269H54.733V40.0169C55.6557 39.6329 57.0397 39.249 58.214 39.249C60.269 39.249 61.0239 39.8889 61.0239 41.6807V56.5269H68.3632V39.6329C68.3632 35.1535 65.9307 33.4043 61.7788 33.4043C58.9689 33.4043 56.6203 34.2576 54.6911 35.836H54.4814V33.8309H47.3938V56.5269Z"
                fill="black"
            />
            <path
                d="M80.1083 56.8682C82.4569 56.8682 84.1344 56.3989 85.0151 55.9296V51.1089C84.428 51.2369 83.5892 51.3648 82.8343 51.3648C81.031 51.3648 80.3599 50.8529 80.3599 49.2744V39.0357H84.8893V33.8309H80.3599V28.4556H72.9787V33.8309H70.2107V39.0357H72.9787V50.341C72.9787 55.3324 75.6208 56.8682 80.1083 56.8682Z"
                fill="black"
            />
            <path
                d="M87.4387 56.5269H94.778V41.1687C96.0362 40.5288 97.7137 40.1449 99.6849 40.1449C100.314 40.1449 101.111 40.2728 101.572 40.3582V33.7883C101.32 33.6603 100.859 33.575 100.272 33.575C97.9234 33.575 95.9104 34.8122 94.7361 36.5613H94.5264V33.8309H87.4387V56.5269Z"
                fill="black"
            />
            <path
                d="M108.788 56.9535C111.555 56.9535 113.527 56.1429 114.449 54.7778H114.701L115.204 56.5269H121.327V40.3582C121.327 35.2388 117.93 33.4043 112.436 33.4043C108.955 33.4043 106.02 33.8309 103.713 34.7268V39.6756C105.265 39.2916 107.404 38.993 109.626 38.993C112.856 38.993 113.988 39.5049 113.988 41.894V42.8752L109.92 43.4298C105.055 44.1124 101.826 45.7335 101.826 50.5116C101.826 54.8631 104.678 56.9535 108.788 56.9535ZM111.304 52.2181C109.92 52.2181 108.871 51.6208 108.871 49.7437C108.871 48.0372 109.626 47.312 111.388 47.184L113.988 46.928V51.3222C113.485 51.8341 112.436 52.2181 111.304 52.2181Z"
                fill="black"
            />
            <path d="M124.661 56.5269H132V25H124.661V56.5269Z" fill="black" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.3252 6.7494V16.1452C67.3252 19.8732 70.3918 22.8949 74.1714 22.8949H125.071C128.852 22.8949 131.917 19.8732 131.917 16.1452V6.7494C131.917 3.02272 128.852 0 125.071 0H74.1714C70.3918 0 67.3252 3.02272 67.3252 6.7494Z"
                fill="#D42626"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M103.734 4.37622H107.607L110.705 14.2447H110.764L113.654 4.37622H117.527L120.624 14.2447H120.683L123.662 4.37622H127.266L122.411 18.4746H118.866L115.47 8.84081H115.411L112.403 18.4746H108.709L103.734 4.37622"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M96.4497 15.694C99.071 15.694 100.65 13.6683 100.65 11.4652C100.65 9.26236 99.071 7.23568 96.4497 7.23568C93.8284 7.23568 92.2497 9.26236 92.2497 11.4652C92.2497 13.6683 93.8284 15.694 96.4497 15.694M96.45 4.06372C100.769 4.06372 104.225 7.03008 104.225 11.4652C104.225 15.8997 100.769 18.8673 96.45 18.8673C92.1309 18.8673 88.6758 15.8997 88.6758 11.4652C88.6758 7.03008 92.1309 4.06372 96.45 4.06372"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.0923 4.41634H77.4883V6.67778H77.548C78.2029 5.26826 79.5434 4.06372 81.9266 4.06372C85.7692 4.06372 87.1396 6.73671 87.1396 9.61467V18.5147H83.5653V11.3774C83.5653 9.82028 83.4461 7.23569 80.9437 7.23569C78.5907 7.23569 77.6669 8.93953 77.6669 10.8192V18.5147H74.0923V4.41634"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64.5847 11.4468C64.5657 6.8661 62.7738 4.23139 58.3649 4.23139C56.9007 4.23139 55.6062 4.60982 54.3906 5.6124V0.283447H50.9512V18.5972H54.3906V10.8679C54.3906 9.22943 55.2608 7.53491 58.1298 7.53491C59.9598 7.53491 61.134 8.7636 61.134 10.3587C61.1495 10.7149 61.1524 11.0411 61.1524 11.3027V18.5972H64.5912V11.4629L64.5847 11.4468"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.0242 15.1994V15.1911L49.0097 15.1994C47.4429 15.1208 45.9324 13.9723 45.4228 12.7265C45.3076 12.4446 45.2417 11.9788 45.2417 11.4449V7.43773H49.0242V4.54177H45.2417V0.593506H41.8022V10.7454C41.8022 11.2216 41.8495 12.2245 41.94 12.6752C42.6058 16.0028 45.611 18.5133 49.2163 18.5133V15.2082C49.1519 15.2082 49.0886 15.2019 49.0242 15.1994"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.68 4.18715C39.5462 4.171 39.4113 4.15991 39.2716 4.15991L39.2066 4.16403C39.1778 4.16403 39.1497 4.15991 39.1203 4.15991C37.6563 4.15991 36.0534 4.50255 35.145 5.79141V4.50065H31.7065V18.5257H35.145V11.2844L35.1463 11.2353C35.1463 9.28651 36.3095 7.46375 39.1203 7.46375C39.6128 7.46375 40.0826 7.554 40.5189 7.71234V4.29291C40.2443 4.24161 39.9643 4.20931 39.68 4.18715"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1898 7.46407C24.915 7.46407 26.3838 8.52556 26.9558 10.0136H19.4231C19.9958 8.52556 21.4633 7.46407 23.1898 7.46407M26.5277 13.5837C25.8049 14.6294 24.58 15.3178 23.1894 15.3178C21.3637 15.3178 19.8244 14.1325 19.3306 12.5096H27.047L27.046 12.5121H30.5417C30.5799 12.1362 30.6025 11.7724 30.6025 11.3914C30.6025 7.39725 27.2837 4.15991 23.1885 4.15991C19.0942 4.15991 15.7744 7.39725 15.7744 11.3914C15.7744 15.3856 19.0942 18.6226 23.1885 18.6226C25.6393 18.6226 27.8118 17.4636 29.1616 15.6766L26.5277 13.5837"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.43077 15.3175C5.20691 15.3175 3.40441 13.5597 3.40441 11.3915C3.40441 9.22225 5.20691 7.46346 7.43077 7.46346C9.21225 7.46346 10.7195 8.59334 11.2508 10.1561C11.353 10.5298 11.4064 10.9199 11.4064 11.3161C11.4064 12.1546 11.1684 12.9634 10.7286 13.6401C10.0003 14.6538 8.79607 15.3175 7.43077 15.3175M7.43022 4.15991C3.33595 4.15991 0.0164922 7.39693 0.0164922 11.3914C0.0164922 11.4259 0.0216662 11.4586 0.0216662 11.4921L0 11.5254V22.6339H3.43943V17.4779C4.59259 18.1992 5.9595 18.6223 7.43022 18.6223C11.5251 18.6223 14.8443 15.385 14.8443 11.3914C14.8443 7.39693 11.5251 4.15991 7.43022 4.15991"
                fill="black"
            />
        </svg>
    )
}

export default PerthNowCentralLogo
